import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import React from "react";
import { StrapiInvestment } from "../../../graphql-types";

interface InvestmentCardProps
  extends Pick<StrapiInvestment, "name" | "website" | "icon"> {}
const InvestmentCard: React.FC<InvestmentCardProps> = ({
  name,
  website,
  icon,
}) => {
  const image = getImage(icon!.localFile! as any); // force cast

  return (
    // <div className="py-8 md:px-5 px-1 mx-auto">
      <a href={website} target="_blank" className="mx-auto">
        <GatsbyImage
          image={image!}
          alt={name}
          className="grayscale opacity-40 hover:grayscale-0 hover:opacity-100 transition-all mx-auto"
        />
      </a>
    // </div>
  );
};

export default InvestmentCard;
