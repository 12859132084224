import { graphql, useStaticQuery } from "gatsby";
import * as EmailValidator from "email-validator";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useState, useContext } from "react";
import ElevateButton from "../ElevateButton";
import axios from "axios";
import ElevateModal from "../ElevateModal";
import ReactMarkdown from "react-markdown";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import AllowCookiesContext from "../../contexts/cookiesContext";
import { ContactComponentQuery } from "../../../graphql-types";

const GATSBY_API_URL = process.env.GATSBY_API_URL;

const ContactComponent: React.FC = () => {
  let executeRecaptcha = null;
  const consent = useContext(AllowCookiesContext);
  // run recaptcha validator if user grants consent
  if (consent) {
    executeRecaptcha = useGoogleReCaptcha().executeRecaptcha;
  }

  const queryResults = useStaticQuery<ContactComponentQuery>(query);
  const { header, subHeader, contactUsText, recaptchaPolicy } = queryResults.contact;

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);


  const isFormFilled = () => !!firstName.length && !!lastName.length && !!email.length;

  const submitForm = async () => {
    if (!EmailValidator.validate(email)) {
      setShowEmailError(true);
      return;
    }
    setShowEmailError(false);

    let reCaptchaToken = executeRecaptcha ? await executeRecaptcha('contactForm') : null;

    try {
      const res = await axios.post(
        `${GATSBY_API_URL}/api/form-submission`,
        {
          reCaptchaToken,
          firstName,
          lastName,
          company,
          email
        }
      )
      if (res.status === 200) {
        setShowModal(true)
        setShowError(false);
        setFirstName("");
        setLastName("");
        setCompany("");
        setEmail("");
      }
    }
    catch (err) {
      setShowError(true);
    }
  }


  return (
    <>
      {/* Desktop */}
      <div className="hidden md:grid grid-cols-2 3xl:w-[70%] 2xl:w-[80%] xl:w-[90%] m-auto xl:gap-14 gap-8">
        <GatsbyImage loading="eager" image={getImage(queryResults.contactImage.childImageSharp)} objectFit="cover" alt="" imgStyle={{ borderRadius: "1.5rem" }}></GatsbyImage>
        {
          /* ZeroFox beacon */
          process.env.GATSBY_APPLICATION_URL === "https://www.bamelevate.com" && <img src="https://d21y75miwcfqoq.cloudfront.net/f9b407c1" style={{ position: "absolute" }} referrerPolicy="no-referrer-when-downgrade" />
        }
        <div className="grid place-content-center">
          <ReactMarkdown children={header.data.childMarkdownRemark.rawMarkdownBody} className="text-blue font-bold text-2xl lg:text-3xl xl:text-5xl leading-none text-left" />
          <ReactMarkdown children={subHeader.data.childMarkdownRemark.rawMarkdownBody} className="mt-5 prose" />
          <div className="grid grid-cols-2 gap-3 mt-7">
            <input type="text" className="border border-gray-light rounded md:p-2 text-gray-dark font-sans mb-4 lg:mb-6 input-field" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)}></input>
            <input type="text" className="border border-gray-light rounded md:p-2 text-gray-dark font-sans mb-4 lg:mb-6 input-field" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)}></input>
            <input className="col-span-2 border border-gray-light rounded md:p-2 text-gray-dark font-sans mb-4 lg:mb-6 input-field" type="text" placeholder="Company (optional)" value={company} onChange={(e) => setCompany(e.target.value)}></input>
            <input className="col-span-2 border border-gray-light rounded md:p-2 text-gray-dark font-sans mb-4 lg:mb-6 input-field" type="email" placeholder="Email Address" value={email} onChange={(e) => setEmail(e.target.value)}></input>
            <div className="col-span-2">
              <ReactMarkdown className="prose leading-tight" children={contactUsText.data.childMarkdownRemark.rawMarkdownBody} />
            </div>
            <div className="col-span-2">
              <ReactMarkdown className="prose leading-tight" children={recaptchaPolicy.data.childMarkdownRemark.rawMarkdownBody} />
            </div>
            <div className={`col-span-2 text-red text-xs ${showEmailError ? "block" : "hidden"}`}>Invalid email format. Please fix and try again.</div>
            <div className={`col-span-2 text-red text-xs ${showError ? "block" : "hidden"}`}>Error submitting form. Please try again later.</div>
            <ElevateButton disabled={!isFormFilled()} onClick={() => submitForm()} className="mt-4">Join Us</ElevateButton>
            <div></div>
          </div>
        </div>
      </div>

      {/* Mobile */}
      <div className="md:hidden content-center w-[80%] m-auto pt-16">
        {
          /* ZeroFox beacon */
          process.env.GATSBY_APPLICATION_URL === "https://www.bamelevate.com" && <img src="https://d21y75miwcfqoq.cloudfront.net/f9b407c1" style={{ position: "absolute" }} referrerPolicy="no-referrer-when-downgrade" />
        }
        <ReactMarkdown children={header.data.childMarkdownRemark.rawMarkdownBody} className="font-sans font-bold text-2xl text-center leading-none m-auto" />
        <ReactMarkdown children={subHeader.data.childMarkdownRemark.rawMarkdownBody} className="prose mt-5 leading-tight text-center text-sm" />
        <div className="grid grid-cols-2 gap-3 mt-14">
          <input type="text" className="bg-off-white font-sans text-gray-dark input-field-mobile mb-5" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)}></input>
          <input type="text" className="bg-off-white font-sans text-gray-dark input-field-mobile mb-5" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)}></input>
          <input className="col-span-2 bg-off-white font-sans text-gray-dark input-field-mobile mb-5" type="text" placeholder="Company (optional)" value={company} onChange={(e) => setCompany(e.target.value)}></input>
          <input className="col-span-2 bg-off-white font-sans text-gray-dark input-field-mobile mb-5" type="email" placeholder="Email Address" value={email} onChange={(e) => setEmail(e.target.value)}></input>
          <div className="col-span-2">
            <ReactMarkdown className="prose leading-tight" children={contactUsText.data.childMarkdownRemark.rawMarkdownBody} />
          </div>
          <div className="col-span-2" >
            <ReactMarkdown className="prose leading-tight" children={recaptchaPolicy.data.childMarkdownRemark.rawMarkdownBody} />
          </div>
          <div className={`col-span-2 text-red text-xs ${showEmailError ? "block" : "hidden"}`}>Invalid email format. Please fix and try again.</div>
          <div className={`col-span-2 text-red text-xs ${showError ? "block" : "hidden"}`}>Error submitting form. Please try again later.</div>
          <div className="col-span-2 flex">
            <div className="grow h-0"></div>
            <ElevateButton disabled={!isFormFilled()} onClick={() => submitForm()}><div className="w-[138px]">Join us</div></ElevateButton>
            <div className="grow h-0"></div>
          </div>
        </div>
      </div>

      <ElevateModal visible={showModal} onClose={() => setShowModal(false)} sizing="h-[350px] w-[350px] m-auto fixed top-[200px]" center>
        <div className="flex h-full">
          <div className="m-auto font-bold">
            <div className="text-blue text-xl xl:text-3xl">Thanks</div>
            <div>for getting in touch.</div>
            <div>Your inquiry has been sent to us.</div>
          </div>
        </div>
      </ElevateModal>
    </>
  )
}



export const query = graphql`
  query ContactComponent {
    contactImage: file(relativePath: {eq: "header_images/img_contact.png" }) 
      {
        childImageSharp {
        gatsbyImageData(quality: 60, layout: FULL_WIDTH)
      }
    }
    contact: strapiContactPage {
      header {
        data {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }
      subHeader {
        data {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }
      contactUsText {
        data {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }
      recaptchaPolicy {
        data {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }
    }
  }
`

export default ContactComponent;