import { navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import { useSwipeable } from "react-swipeable";
import ElevateButton from "../ElevateButton";
import InvestmentCard from "../InvestmentCard";

interface InvestsmentsNewProps {
    investments: any;
}

const ORGS_PER_CARD = 4;

const InvestmentsNew: React.FC<InvestsmentsNewProps> = ({ investments = [] }) => {
    const [currentSlide, setSlide] = useState(0);
    const [investmentCards, setInvestmentCards] = useState<[][]>([]);


    useEffect(() => {
        let startIndex = 0;
        const investmentCards = [];

        for (let i = 0; i < Math.ceil(investments.length / ORGS_PER_CARD)  ; i++) {
            investmentCards.push(investments.slice(startIndex, startIndex + ORGS_PER_CARD));
            startIndex += ORGS_PER_CARD
        }
        setInvestmentCards(investmentCards);
    }, []);


    const handlers = useSwipeable({
        onSwiped: (eventData) => {
            const { dir } = eventData;
            if (dir === "Left") {
                let newSlide = currentSlide + 1;
                if (newSlide === investmentCards.length) newSlide = 0;
                setSlide(newSlide)
            }
            else if (dir === "Right") {
                let newSlide = currentSlide - 1;
                if (newSlide === - 1) newSlide = investmentCards.length - 1;
                setSlide(newSlide)
            }
        }
    });

    return (
        <>
            {
                investmentCards.length > 0 &&
                <>
                    {/* Desktop */}
                    <div className="hidden lg:grid pb-16">
                        <div className="grid grid-cols-3 gap-4">
                            {
                                investmentCards.map((investmentGroup, i) => {
                                    return (
                                        <div className="grid grid-cols-2 gap-4" key={i}>
                                            {
                                                investmentGroup.map((investment: any) => {
                                                    return (
                                                        <InvestmentCard name={investment.name} website={investment.website} icon={investment.icon} key={investment.name} />
                                                    )
                                                })
                                            }
                                        </div>
                                    )

                                })
                            }
                        </div>
                    </div>
                    <div className="hidden lg:grid place-items-center">
                        <ElevateButton className="p-8" onClick={() => navigate(`/portfolio`)}>See Who We've Helped</ElevateButton>
                    </div>

                    {/* Mobile */}
                    <div className="lg:hidden flex flex-col md:h-auto text-base" {...handlers}>
                        <div className="grid grid-cols-2 content-center">
                            {
                                investmentCards[currentSlide].map((investment: any) => {
                                    return (
                                        <InvestmentCard name={investment.name} website={investment.website} icon={investment.icon} key={investment.website} />
                                    )
                                })
                            }
                        </div>
                        <div className="w-full flex flex-row space-x-1">
                            <div className="grow h-0"></div>
                            {
                                investmentCards.map((_, i: number) => {
                                    return (
                                        <div key={i} className={`border rounded-full h-2 w-2 mx-0 m-auto ${currentSlide === i ? "border-orange bg-orange" : "border-gray-light bg-white"}`}></div>
                                    )
                                })
                            }
                            <div className="grow h-0"></div>
                        </div>
                        <ElevateButton className="self-center my-9" onClick={() => navigate(`/portfolio`)}><div>&nbsp;&nbsp;See Who We've Helped&nbsp;&nbsp;</div></ElevateButton>
                    </div>
                </>
            }
        </>
    )
}

export default InvestmentsNew;
