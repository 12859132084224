import { navigate } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import { useSwipeable } from "react-swipeable";
import ElevateButton from "../ElevateButton";

interface CardCarouselProps {
    featuredNews: any;
}

const CardCarousel: React.FC<CardCarouselProps> = ({ featuredNews }) => {
    featuredNews = featuredNews.edges.map(edge => edge.node);
    const [slideIndex, setSlideIndex] = useState(0);
    const nextSlide = () => {
        let nextSlide = slideIndex + 1;
        if (nextSlide === featuredNews.length) nextSlide = 0;
        setSlideIndex(nextSlide);
    }

    const formatDate = (date: string) => {
        return new Date(date).toLocaleDateString("en-US", {
            day: "numeric",
            month: "short",
            year: "numeric",
        })
    }

    const handlers = useSwipeable({
        onSwiped: (eventData) => {
            const { dir } = eventData;
            if (dir === "Left") {
                let newSlide = slideIndex + 1;
                if (newSlide > featuredNews.length - 1) newSlide = 0;
                setSlideIndex(newSlide)
            }
            else if (dir === "Right") {
                let newSlide = slideIndex - 1;
                if (newSlide === - 1) newSlide = featuredNews.length - 1;
                setSlideIndex(newSlide)
            }
        }
    });

    return (
        <>
            {/* Desktop */}
            <div className="hidden lg:flex place-content-center w-[820px] xl:w-[960px] 2xl:w-[1100px] m-auto mb-14">
                <div className="rounded-l-3xl bg-white flex flex-col w-1/2 p-9 xl:p-11 2xl:p-12">
                    <div className="w-[50%] mb-4 grid content-center h-[90px] xl:h-[100px] 2xl:h-[115px]">
                        <img alt={featuredNews[slideIndex].title} src={featuredNews[slideIndex].thumbnail!.localFile!.publicURL} className="grayscale opacity-40 object-cover" />
                    </div>
                    <div className="text-xs text-gray-dark pb-1">{formatDate(featuredNews[slideIndex].date)}</div>
                    <div className="">
                        <div className="mb-2 text-lg leading-tight limit-lines w-[80%] font-semibold">{featuredNews[slideIndex].title}</div>
                        {featuredNews[slideIndex].author? <div className="mb-4 text-sm font-semibold">By {featuredNews[slideIndex].author}</div> : 
                        <div className="mb-4"></div>}
                        <p className="text-gray limit-lines w-[80%]">{featuredNews[slideIndex].preview}</p>
                    </div>
                    <div className="grow h-0"></div>
                    <div className="mb-5">
                        <ElevateButton className="" onClick={() => navigate("/news/" + featuredNews[slideIndex].slug)}>{featuredNews[slideIndex].body.data.childMarkdownRemark.timeToRead} Minute Read</ElevateButton>
                    </div>
                    <div className="w-full border-t flex pt-3">
                        <div className="flex space-x-1 pt-2">
                            {
                                featuredNews.map((item: any, i: number) => {
                                    return (
                                        <div key={item.title} className={`rounded-full h-2 w-2 border ${slideIndex === i ? "bg-orange border-orange" : "bg-white border-gray-light"}`}></div>
                                    )
                                })
                            }
                        </div>
                        <div className="grow h-0"></div>
                        <div className="font-bold hover:cursor-pointer" onClick={nextSlide}>Next Story</div>
                    </div>
                </div>
                <div className="h-full w-1/2">
                    <GatsbyImage loading="eager" image={getImage(featuredNews[slideIndex].stockImage.localFile.childImageSharp)} objectFit="cover" alt="" imgStyle={{borderRadius: "0 1.5rem 1.5rem 0"}}></GatsbyImage>
                </div>
            </div>

            {/* Mobile */}
            <div className="lg:hidden flex flex-col m-auto mb-14 px-3 max-w-[440px]" {...handlers}>
                <div className="h-[240px]">
                    <GatsbyImage loading="eager" image={getImage(featuredNews[slideIndex].stockImageSquare.localFile.childImageSharp)} objectPosition="50% 15%" alt="" imgStyle={{borderRadius: "1.5rem 1.5rem 0 0"}}></GatsbyImage>
                </div>
                <div className="rounded-b-3xl bg-white flex flex-col pb-4 px-4 xs:px-5 xs:pb-5 min-h-[440px]">
                    <div className="w-[40%] grid content-center my-6 h-[90px]">
                        <img alt={featuredNews[slideIndex].title} src={featuredNews[slideIndex].thumbnail!.localFile!.publicURL} className="grayscale opacity-40" />
                    </div>
                    <div className="text-xs text-gray-dark pb-1">{formatDate(featuredNews[slideIndex].date)}</div>
                    <div className="flex flex-col">
                        <div className="text-base font-sans mb-2 leading-tight limit-lines">{featuredNews[slideIndex].title}</div>
                        { featuredNews[slideIndex].author ? <div className="text-xs font-sans mb-4">By {featuredNews[slideIndex].author}</div> : 
                        <div className="mb-2"></div> }
                        <p className="text-sm text-gray-dark limit-lines">{featuredNews[slideIndex].preview}</p>
                    </div>
                    <div className="grow h-0"></div>

                    <ElevateButton onClick={() => navigate("/news/" + featuredNews[slideIndex].slug)}>{featuredNews[slideIndex].body.data.childMarkdownRemark.timeToRead} Minute Read</ElevateButton>
                    <div className="w-full flex space-x-1 mt-6">
                        <div className="grow h-0"></div>
                        {
                            featuredNews.map((item: any, i: number) => {
                                return (
                                    <div key={item.title} className={`rounded-full h-2 w-2 border mx-0 m-auto ${slideIndex === i ? "bg-orange border-orange" : "bg-white border-gray-light"}`}></div>
                                )
                            })
                        }
                        <div className="grow h-0"></div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default CardCarousel