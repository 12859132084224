import React from "react";
import ReactMarkdown from "react-markdown";

type CultureCardProps = {
  title: string;
  desc: any;
  icon: string;
  className?: string;
};
const CultureCard: React.FC<CultureCardProps> = ({ title, desc, icon, className =  ""}) => {
  return (
    <div className={className}>
        <img
          src={icon}
          alt={title}
          className="object-contain h-16 lg:h-24"
        />
      <div className="h-[1px] bg-gray-light w-full my-4"></div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-2">
      <p className="text-base leading-tight w-1/3 lg:w-[50%] font-sans font-semibold my-4">
        {title}
      </p>
      { desc?.data?.childMarkdownRemark?.rawMarkdownBody ? <ReactMarkdown className="prose text-sm text-gray-dark">{desc?.data?.childMarkdownRemark?.rawMarkdownBody}</ReactMarkdown> : <></> }
      </div>
    </div>
  );
};

export default CultureCard;
