import React from "react";
import { graphql, useStaticQuery, navigate } from "gatsby";
import { getImage, ImageDataLike } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import CultureCard from "../components/CultureCard";
import Footer from "../components/Footer";
import { HomePageQuery } from "../../graphql-types";
import SectionHeader from "./../components/SectionHeader";
import HeroBanner from "../components/HeroBanner";

import "./../styles/index.scss";

import MenuBar from "../components/MenuBar";
import PageContent from "../components/PageContent";
import { mustBeDefined } from "../utils";
import CardCarousel from "../components/CardCarousel/CardCarousel";
import Investments from "../components/Investments";
import ContactComponent from "../components/Contact";
// import CaseStudyComponent from "../components/CaseStudy";
import ElevateButton from "../components/ElevateButton";
import InvestmentsNew from "../components/InvestmentsNew";

const HomePage: React.FC = () => {
  const queryResults = useStaticQuery<HomePageQuery>(query);

  // Sanity checks
  const sections = mustBeDefined(queryResults.sections).otherwise(
    "CMS_DATA_MISSING",
    "HomePageQuery.sections"
  );

  const cultureCards = mustBeDefined(queryResults.cultureCards).otherwise(
    "CMS_DATA_MISSING",
    "HomePageQuery.cultureCards"
  )

  const homeBanner = mustBeDefined(queryResults.homeBanner).otherwise(
    "IMAGE_DATA_MISSING",
    "HomePageQuery.homeBanner"
  );

  const featuredNews = mustBeDefined(queryResults.featuredNews).otherwise(
    "CMS_DATA_MISSING",
    "HomePageQuery.featuredNews"
  )

  const consumerInvestments = mustBeDefined(queryResults.consumerInvestments).otherwise(
    "CMS_DATA_MISSING",
    "HomePageQuery.consumerInvestments"
  );

  const healthcareInvestments = mustBeDefined(queryResults.healthcareInvestments).otherwise(
    "CMS_DATA_MISSING",
    "HomePageQuery.healthcareInvestments"
  );

  const enterpriseInvestments = mustBeDefined(queryResults.enterpriseInvestments).otherwise(
    "CMS_DATA_MISSING",
    "HomePageQuery.enterpriseInvestments"
  );

  const fintechInvestments = mustBeDefined(queryResults.fintechInvestments).otherwise(
    "CMS_DATA_MISSING",
    "HomePageQuery.fintechInvestments"
  );
  const homeBannerImage = getImage(homeBanner as ImageDataLike);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="BAM Elevate home page" />
        <title>BAM Elevate - Home</title>
      </Helmet>
      <main className="w-screen bg-off-white">
        <MenuBar />

        <HeroBanner image={homeBannerImage!} title={sections.heroTitle} description={sections.heroDescription?.data?.childMarkdownRemark?.rawMarkdownBody} disclaimer={sections.disclaimer?.data?.childMarkdownRemark?.rawMarkdownBody} />
        <PageContent>
          <SectionHeader
            centered
            title={sections.cultureSectionTitle?.data?.childMarkdownRemark?.rawMarkdownBody}
          />
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-20 gap-12 justify-items-center">
            {cultureCards.edges.map((card) => {
              return (
                <CultureCard key={card.node.title} {...card.node} icon={card!.node.icon!.localFile!.publicURL!} />
              );
            })}
          </div>
        </PageContent>
        
        <PageContent>
          <CardCarousel featuredNews={featuredNews} />
        </PageContent>

        <PageContent>
          <SectionHeader
            centered
            title={sections.portfolioSectionTitle?.data?.childMarkdownRemark?.rawMarkdownBody}
            description={sections.portfolioSectionDescription?.data?.childMarkdownRemark?.rawMarkdownBody}
            descriptionClassName="px-0 w-auto max-w-[650px] pt-3 font-normal mx-auto text-center"
          />
          {/* <Investments consumerInvestments={consumerInvestments.edges.concat(healthcareInvestments.edges)} enterpriseInvestments={enterpriseInvestments.edges} fintechInvestments={fintechInvestments.edges} /> */}
          <InvestmentsNew investments={sections.portfolioSectionFeaturedInvestments}/>
        </PageContent>
        <PageContent>
          <ContactComponent/>
        </PageContent>

        <PageContent>
          <Footer />
        </PageContent>
      </main>
    </>
  );
};

export default HomePage;

export const query = graphql`
  query HomePage {
    sections: strapiHomePage {
      heroTitle
      heroDescription {
        data {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }
      disclaimer {
        data {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }
      cultureSectionTitle {
        data {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }
      portfolioSectionTitle {
        data {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }
      portfolioSectionDescription {
        data {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }
      portfolioSectionFeaturedInvestments {
        name
        icon {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 300)
            }
          }
        }
        website
      }
    }
    cultureCards: allStrapiCultureCard(sort: {fields: pageOrder}) {
      edges {
        node {
          title
          desc {
            data {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
          }
          icon {
            localFile {
              publicURL
            }
          }
        }
      }
    }
    featuredNews: allStrapiBlogPost(sort: {fields: date, order: DESC}) {
      edges {
        node {
          id
          title
          author
          body {
            data {
              childMarkdownRemark {
                rawMarkdownBody
                timeToRead
              }
            }
          }
          date
          slug
          isFeatured
          preview
          thumbnail {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(quality: 90, layout: FULL_WIDTH)
              }
            }
          }
          stockImage {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(quality: 90, layout: FULL_WIDTH)
              }
            }
          }
          stockImageSquare {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(quality: 90, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
    consumerInvestments: allStrapiInvestment(
      filter: { category: { eq: "Consumer" }, isFeatured: {eq: true} },
      limit: 4
    ) {
      edges {
        node {
          name
          website
          icon {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 300)
              }
            }
          }
        }
      }
    }
    healthcareInvestments: allStrapiInvestment(
      filter: { category: { eq: "Healthcare" }, isFeatured: {eq: true} },
      limit: 4
    ) {
      edges {
        node {
          name
          website
          icon {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 300)
              }
            }
          }
        }
      }
    }
    fintechInvestments: allStrapiInvestment(
      filter: { category: { eq: "FinTech" }, isFeatured: {eq: true} },
      limit: 4
    ) {
      edges {
        node {
          website
          name
          icon {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 300)
              }
            }
          }
        }
      }
    }
    enterpriseInvestments: allStrapiInvestment(
      filter: { category: { eq: "Enterprise_Software" }, isFeatured: {eq: true} },
      limit: 4
    ) {
      edges {
        node {
          name
          website
          icon {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 300)
              }
            }
          }
        }
      }
    }
    homeBanner: file(
      relativePath: { eq: "header_images/img_home_main_1920w.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: CONSTRAINED)
      }
    }
  }
`;
